import React, {useState, useEffect} from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import "../../assets/styles/marketplace-overview.css";

import DataService from "../../services/data.service";

import logo from "../../assets/img/logo.svg";

const Assets = () => {
    const [nfts, setNFTOverview] = useState([]);
    const [page, setPage] = useState(1);

    const NFTOverview = async () => {
        // setCurrPage(page);
        DataService.getNFTOverview(page)
        .then( response => {
            // console.log(response.data.result);
            console.log(response.data)
            setNFTOverview(response.data.result)
        })
        .catch(function (error) {
            if (error.response) {
                console.log(error.response.data.error);
            }
        });
    }

    useEffect(() => {
        NFTOverview(1);
    }, [setNFTOverview]);

    return (
        <>
            
            <section className="section__wrapper">

                <Container>
                    <Row>
                        {nfts.map((item, index) => (
                            <Col md={3} className="my-3">
                                {/* <a className="collection" href={'/data/asset/' + item.assetHash}> */}
                                    <Card style={{height: '100%'}}>
                                        <figure style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', padding: '20px'}}>
                                        {item.data ? (
                                            <Card.Img variant="top" src={`https://rapidsnetwork.mypinata.cloud/ipfs/${item.data}`}/>
                                        ) : (
                                            <Card.Img variant="top" src={logo} width={225} height={225} style={{margin: 'auto'}} />
                                        )}
                                        </figure>
                                        {/* {item.collectionLogo ?
                                            <div className="collection__overlay">
                                                <img src={item.collectionLogo} width={50} height={50} alt={item.collection} />
                                            </div> : '' 
                                        } */}
                                        {/* {item.isVideo ? <div className="play__overlay"></div> : ''} */}
                                        
                                        <Card.Body>
                                            <Card.Title>{item.name}</Card.Title>
                                            {item.subcategory ?<Card.Text><small>{item.subcategory}</small></Card.Text>:''}
                                        </Card.Body>
                                    </Card>
                                {/* </a> */}
                            </Col>
                        ))}
                    </Row>
                </Container>

            </section>
        
        </>
    );
}

export default Assets;
