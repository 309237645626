import React from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';

const TicketsDetail = () => {
    return (
        <>
            
            <section className="section__wrapper">
                
                <Container>
                    <Row md={8}>
                        
                    </Row>
                </Container>

            </section>
        
        </>
    );
}

export default TicketsDetail;
