import React from "react";
import { Container, Row, Col, Card, Button, Badge } from "react-bootstrap";
import "../assets/styles/marketplace-overview.css";

const items = [
    {
        title: 'Bane of Immortals',
        collection: 'Lost Relics',
        collectionLogo: 'https://via.placeholder.com/50x50',
        ipfs: 'https://cdn.enjinx.io/metadata/raw/b8118f1db727be7b3382b657af80fbf75ae2f1d3/0677bdb15bca389acb0251fed3a522aac016ede7.jpg',
        assetHash: '0677bdb15bca389acb0251fed3a522aac016ede7',
        listings: '10',
        price: '2'
    },
    {
        title: 'Bane of Immortals',
        collection: 'Lost Relics',
        collectionLogo: 'https://via.placeholder.com/50x50',
        ipfs: 'https://cdn.enjinx.io/metadata/raw/b8118f1db727be7b3382b657af80fbf75ae2f1d3/0677bdb15bca389acb0251fed3a522aac016ede7.jpg',
        assetHash: '0677bdb15bca389acb0251fed3a522aac016ede7',
        listings: '10',
        price: '2'
    },
    {
        title: 'Bane of Immortals',
        collection: 'Lost Relics',
        collectionLogo: 'https://via.placeholder.com/50x50',
        ipfs: 'https://cdn.enjinx.io/metadata/raw/b8118f1db727be7b3382b657af80fbf75ae2f1d3/0677bdb15bca389acb0251fed3a522aac016ede7.jpg',
        assetHash: '0677bdb15bca389acb0251fed3a522aac016ede7',
        listings: '10',
        price: '2'
    },
    {
        title: 'Bane of Immortals',
        collection: 'Lost Relics',
        collectionLogo: 'https://via.placeholder.com/50x50',
        ipfs: 'https://cdn.enjinx.io/metadata/raw/b8118f1db727be7b3382b657af80fbf75ae2f1d3/0677bdb15bca389acb0251fed3a522aac016ede7.jpg',
        assetHash: '0677bdb15bca389acb0251fed3a522aac016ede7',
        listings: '10',
        price: '2'
    },
    {
        title: 'Bane of Immortals',
        collection: 'Lost Relics',
        collectionLogo: 'https://via.placeholder.com/50x50',
        ipfs: 'https://cdn.enjinx.io/metadata/raw/b8118f1db727be7b3382b657af80fbf75ae2f1d3/0677bdb15bca389acb0251fed3a522aac016ede7.jpg',
        assetHash: '0677bdb15bca389acb0251fed3a522aac016ede7',
        listings: '10',
        price: '2'
    },
]

const Marketplace = () => {
    return (
        <>
            <section className="section__wrapper">

                <Container>
                    <Row>
                        {items.map((item, index) => (
                            <Col md={3} className="my-3">
                                <a className="collection" href={'/data/asset/' + item.assetHash}>
                                    <Card>
                                        <Card.Img variant="top" src={item.ipfs} with={275} height={275} />
                                        {item.collectionLogo ?
                                            <div className="collection__overlay">
                                                <img src={item.collectionLogo} width={50} height={50} alt={item.collection} />
                                            </div> : '' 
                                        }
                                        
                                        <Card.Body>
                                            <Card.Title>{item.title}</Card.Title>
                                            {item.collection ?<Card.Text><small>{item.collection}</small></Card.Text>:''}
                                            <Badge bg="info">{item.listings} listings</Badge>
                                            <Card.Text>
                                                Some quick example text to build on the card title and make up the bulk of
                                                the card's content.
                                            </Card.Text>
                                            <Button variant="info" size="sm">buy</Button>
                                            <Button variant="success" size="sm" disabled>{item.price} RPD</Button>
                                        </Card.Body>
                                    </Card>
                                </a>
                            </Col>
                        ))}
                    </Row>
                </Container>

            </section>
        
        </>
    );
}

export default Marketplace;
