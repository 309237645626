import React from "react";
import {Container, Row, Col, Tabs, Tab, Badge} from 'react-bootstrap';
import AssetActivity from '../components/AssetDetail/Activity';
import AssetDetail from '../components/AssetDetail/Detail';
import AssetHolders from '../components/AssetDetail/Holders';
import AssetTransactions from '../components/AssetDetail/Transactions';

const data = [{
    assetName: 'Xilborne, Venom of the Fallen',
    assetId: '0x1e2cdda8dd439e3953a6f43d1a2a7a4c3af39922',
    assetCollection: 'Lost Relics',
    assetCreator: '0x1e2cdda8dd439e3953a6f43d1a2a7a4c3af39922',
    assetCreated: '25 May 2019, 10:09:29',
    assetLogo: 'https://via.placeholder.com/80x80',
    assetPrice: 580,
    assetSaleListings: 10,
    assetSupply: 175,
    assetCirculating: 175,
    transferFee: 25,
    assetFee: 10,
    holders: 88,
    transfers: 279,
    assetDescription: `With the fall of Gahrswurd the Grim, Harpot saw an opportunity to seize the throne.
    
    Conspiring with Welmoth, chief of the guard, Harpot assured they could gain victory over the Gahrswurd loyal, elite guard, The Paragon.
    
    Harpot told Welmoth to bring a large cache of the finest weapons in the city armoury.
    
    Welmoth had his men bring the Xilborne axes, the finest weapons in the city, to Harpot's apothecary quarter.
    
    Harpot had extracted a very potent venom from the glands of 'Tree Runners' a small bear that would leap from the branches of trees and use their venom to subdue their prey.
    
    Harpot then transformed the green venom into a mystical fume which he bound to the Xilborne axes.
    
    Welmoth used these devastating axes, poisoning enemies upon contact, to overwhelm The Paragon, allowing Harpot the apothecary to ascend to the seat of power!`,
    assetProperties : [{
        Rarity : 'Mythical',
        itemTYpe : 'Axe',
        itemSubType : 'Two-Handed',
        itemEffect : '15% chance to cause Poison',
    }],
    assetHolders : [
        {
            address : '0x1e2cdda8dd439e3953a6f43d1a2a7a4c3af39922',
            balance : 1,
            value: 10,
        },
        {
            address : '0x1e2cdda8dd439e3953a6f43d1a2a7a4c3af39921',
            balance : 2,
            value: 20,
        },
    ],
    transactions : [
        {
            transactionId : '0x1e2cdda8dd439e3953a6f43d1a2a7a4c3af39922',
            time : '25 May 2019, 10:09:29',
            from : '0x1e2cdda8dd439e3953a6f43d1a2a7a4c3af39921',
            to : '0x1e2cdda8dd439e3953a6f43d1a2a7a4c3af39921',
            quantity : 1,
        },
        {
            transactionId : '0x1e2cdda8dd439e3953a6f43d1a2a7a4c3af39922',
            time : '25 May 2019, 10:09:29',
            from : '0x1e2cdda8dd439e3953a6f43d1a2a7a4c3af39921',
            to : '0x1e2cdda8dd439e3953a6f43d1a2a7a4c3af39921',
            quantity : 1,
        },
        {
            transactionId : '0x1e2cdda8dd439e3953a6f43d1a2a7a4c3af39922',
            time : '25 May 2019, 10:09:29',
            from : '0x1e2cdda8dd439e3953a6f43d1a2a7a4c3af39921',
            to : '0x1e2cdda8dd439e3953a6f43d1a2a7a4c3af39921',
            quantity : 1,
        }
    ],
    activity : [
        {
            transactionId : '0x1e2cdda8dd439e3953a6f43d1a2a7a4c3af39922',
            time : '25 May 2019, 10:09:29',
            event : '0x1e2cdda8dd439e3953a6f43d1a2a7a4c3af39921',
            quantity : 1,
            price : 10,
        },
        {
            transactionId : '0x1e2cdda8dd439e3953a6f43d1a2a7a4c3af39922',
            time : '25 May 2019, 10:09:29',
            event : '0x1e2cdda8dd439e3953a6f43d1a2a7a4c3af39921',
            quantity : 1,
            price : 10,
        },
        {
            transactionId : '0x1e2cdda8dd439e3953a6f43d1a2a7a4c3af39922',
            time : '25 May 2019, 10:09:29',
            event : '0x1e2cdda8dd439e3953a6f43d1a2a7a4c3af39921',
            quantity : 1,
            price : 10,
        },
    ],
}]

const DetailedPage = () => {
    return (
        <>
            
            <section className="section__wrapper">
                
                <Container>
                    <Row md={8}>
                        <Col>
                            <div className="d-flex align-items-center">
                                <img src={data[0].assetLogo} width={50} height={50} alt={data[0].assetName + 'branding'} />
                                {' '}
                                <h1 className="h1 ml-2">
                                    {data[0].assetName} 
                                    {data[0].assetName ?
                                        data[0].assetTicker
                                        : 
                                        <div></div>
                                    }
                                    <Badge variant="info">NFT</Badge> or <Badge variant="info">Token</Badge> or <Badge variant="info">Simon</Badge>
                                </h1>
                            </div>
                            <hr />
                        </Col>
                    </Row>
                    <Row md={8}>
                        <Col md={12}>
                            <Tabs defaultActiveKey="details" id="uncontrolled-tab-example" className="mb-3">
                                <Tab eventKey="details" title="Details">
                                    <AssetDetail />
                                </Tab>
                                <Tab eventKey="holders" title="Holders">
                                    <AssetHolders />
                                </Tab>
                                <Tab eventKey="transactions" title="Transactions">
                                    <AssetTransactions />
                                </Tab>
                                <Tab eventKey="activity" title="Market Activity">
                                    <AssetActivity />
                                </Tab>
                            </Tabs>
                        </Col>
                    </Row>
                </Container>

            </section>
        
        </>
    );
}

export default DetailedPage;
