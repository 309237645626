import React from "react";
import {Container, Row, Col, Card, Table} from 'react-bootstrap';

const data = [
    {
        test: 'test',
    }
]

const AssetActivity = () => {
    return (
        <>
            
            <section className="section__wrapper">
                
                <Container>
                    <Row md={8}>
                        <Col>
                            <Card>
                                <Table striped responsive hover>
                                    <thead>
                                        <tr>
                                            <th>Transaction</th>
                                            <th>Time</th>
                                            <th>Event</th>
                                            <th>Amount</th>
                                            <th>Asset Price</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {data.map((data, index) => (
                                            <tr>
                                                {/* <td><a href={'https://localhost:3000/data/transaction/' + data.transaction}><span style={{maxWidth: '150px'}} className="d-inline-block text-truncate">{data.transaction}</span></a></td>
                                                <td>{data.asset} <img src={data.assetLogo} width="25" height="25" alt={data.asset} /></td>
                                                <td>{data.time}</td>
                                                <td><a href={'https://localhost:3000/data/address/' + data.from}>{data.from}</a></td>
                                                <td><a href={'https://localhost:3000/data/address/' + data.to}>{data.to}</a></td> */}
                                                <td><a href={'/'}>0x1e2cdda8dd439e3953a6f43d1a2a7a4c3af39921</a></td>
                                                <td>25 May 2021, 10:01:23</td>
                                                <td>Purchased by <a href={'/'}>0x1e2cdda8dd439e3953a6f43d1a2a7a4c3af39922</a></td>
                                                <td>1</td>
                                                <td>250 RPD | $5.00</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                            </Card>
                        </Col>
                    </Row>
                </Container>

            </section>
        
        </>
    );
}

export default AssetActivity;
