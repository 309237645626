import React from "react";
import {Container, Row, Col, Card, Button, ListGroup} from 'react-bootstrap';

const AssetDetail = (props) => {
    return (
        <>
            {/* <Row md={8}>
                <Col col={12}>
                    <div className="d-flex align-items-center">
                        <Button variant="success">buy</Button>
                        <div>
                            <img src={props.branding} width={25} height={25} alt={'item branding'} />
                            <span>
                                [#] on sale, starting from [sale price] RPD | [calculated by CG stats]
                            </span>
                        </div>
                    </div>
                </Col>
            </Row> */}

            <Row md={8}>
                <Col md={12}>
                    <Card>
                        <Card.Body>
                            <Row>
                                <Col md={2}>
                                    Description
                                </Col>
                                <Col md={10}>
                                    {props.description}
                                </Col>
                            </Row>

                            {/* <hr />

                            <Row>
                                <Col md={2}>
                                    Properties
                                </Col>
                                <Col md={10}>
                                    <Row  className="g-4">
                                        <Col>
                                            <Card>
                                                <Card.Body>
                                                    <Card.Title>[Property Title]</Card.Title>
                                                    <Card.Text>
                                                        [Property Ability]
                                                    </Card.Text>
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                        <Col>
                                            <Card>
                                                <Card.Body>
                                                    <Card.Title>[Property Title]</Card.Title>
                                                    <Card.Text>
                                                        [Property Ability]
                                                    </Card.Text>
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                        <Col>
                                            <Card>
                                                <Card.Body>
                                                    <Card.Title>[Property Title]</Card.Title>
                                                    <Card.Text>
                                                        [Property Ability]
                                                    </Card.Text>
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row> */}
                        </Card.Body>
                    </Card>
                </Col>
            </Row>

            <Row className="mt-4">
                <Col md={12}>
                    <Card>
                        <Card.Body>
                            <ListGroup variant="flush">
                                <ListGroup.Item className="d-flex justify-content-between">Creator <span>{props.issuer}</span></ListGroup.Item>
                                <ListGroup.Item className="d-flex justify-content-between">Reserve <span>Success ~ Pending ~ Failed (confirmations)</span></ListGroup.Item>
                                <ListGroup.Item className="d-flex justify-content-between">Project <span>[time created]</span></ListGroup.Item>
                            </ListGroup>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>

            <Row className="mt-4">
                <Col md={12}>
                    <Card>
                        <Card.Body>
                            <ListGroup variant="flush">
                                <ListGroup.Item className="d-flex justify-content-between">Supply Type <span>[token type]</span></ListGroup.Item>
                                <ListGroup.Item className="d-flex justify-content-between">Total Supply <span>{props.supply} {props.name}</span></ListGroup.Item>
                                <ListGroup.Item className="d-flex justify-content-between">Circulating Supply <span>[how much in supply]</span></ListGroup.Item>
                            </ListGroup>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>

            <Row className="mt-4">
                <Col md={12}>
                    <Card>
                        <Card.Body>
                            <ListGroup variant="flush">
                                <ListGroup.Item className="d-flex justify-content-between">Transfer Fee (taxes) <span>[does project have taxes]</span></ListGroup.Item>
                                <ListGroup.Item className="d-flex justify-content-between">Detail Fees <span>[which taxes / royalties are active? Transaction, Marketing, Develop, Buy Back]</span></ListGroup.Item>
                            </ListGroup>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>

            <Row className="mt-4">
                <Col md={12}>
                    <Card>
                        <Card.Body>
                            <ListGroup variant="flush">
                                <ListGroup.Item className="d-flex justify-content-between">Holders <span>[amount token holders]</span></ListGroup.Item>
                                <ListGroup.Item className="d-flex justify-content-between">Transfers <span>[amount of transfers]</span></ListGroup.Item>
                            </ListGroup>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        
        </>
    );
}

export default AssetDetail;
