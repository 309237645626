import React from "react";
import { Container, Row, Col } from "react-bootstrap";

const PageSubscribe = () => {
    return (
        <>
            <section className="section__wrapper">

                <Container style={{height: '100vh'}}>
                    <Row>
                        <Col md={8} className="my-8 mx-auto">
                            
                            <iframe class="mj-w-res-iframe" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" src="https://app.mailjet.com/widget/iframe/7PJR/Nnq" width="100%" height="500px"></iframe>

                            <script type="text/javascript" src="https://app.mailjet.com/statics/js/iframeResizer.min.js"></script>

                        </Col>
                    </Row>
                </Container>

            </section>
        
        </>
    )
}

export default PageSubscribe;