import React, { useEffect, useState } from "react";
import {Container, Row, Col, Table, Card} from 'react-bootstrap';
import CPagi from "../../components/Pagination";
import DataService from "../../services/data.service";

const Tokens = () => {
    const [tokens, setTokenOverview] = useState([]);
    const [page, setPage] = useState("1");
    const [tagList, setTagList] = useState([]);
    const [currPage, setCurrPage] = useState(1);

    const TokenOverview = async (page) => {
        setCurrPage(page);
        DataService.getTokenOverview(page)
        .then( response => {
            // console.log(response.data.result);
            setTokenOverview(response.data.result);
        })
        .catch(function (error) {
            if (error.response) {
                console.log(error.response.data.error);
            }
        })
        .finally(() => {
            console.log('ready')
        });
    }

    useEffect(() => {
        TokenOverview(1);
    }, [setTokenOverview]);

    return (
        <>
            <section className="section__wrapper">
                
                <Container>
                    <Row md={8}>
                        <Col>
                            <div className="d-flex align-items-center justify-content-between">
                                <h1 className="h1 ml-2">Tokens</h1>
                            </div>
                            <hr />
                        </Col>
                    </Row>

                    <Row md={8}>
                        <Col>
                            <Card>
                                <Table striped responsive hover>
                                    <thead>
                                        <tr>
                                            {/* <th>Rank</th> */}
                                            <th>Token</th>
                                            {/* <th>Hash</th> */}
                                            <th>Issuer</th>
                                            <th>Supply</th>
                                            <th>Holders</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    
                                    {tokens.map((data, index) => (
                                            <tr key={index}>
                                                {/* <td>{data.rank}</td> */}
                                                <td>
                                                    {/* {`https://raw.githubusercontent.com/RapidsOfficial/Assets/develop/internal/${data.name.toLowerCase()}/logo-80x80.png` !== null ? */}
                                                    {`https://raw.githubusercontent.com/RapidsOfficial/Assets/develop/internal/rpd/logo-80x80.png` !== null ?
                                                        <img src={`https://raw.githubusercontent.com/RapidsOfficial/Assets/develop/internal/rpd/logo-80x80.png`} width={25} height={25} alt={data.name} />
                                                        : ""
                                                    }
                                                    {' '}
                                                    {/* <a href={`data/token/` + data.name}> */}
                                                        {data.name}{' '}
                                                        [{data.name}]
                                                    {/* </a> */}
                                                </td>
                                                {/* <td></td> */}
                                                <td>
                                                    <a href={`/data/address/${data.issuer}`} title="">{data.issuer}</a>
                                                </td>
                                                <td>{data.supply}</td>
                                                <td>{data.holders}</td>
                                            </tr>
                                        
                                    ))}
                                    </tbody>
                                </Table>
                            </Card>
                        </Col>
                    </Row>

                    <Row>
                        <Col>

                            
                        <CPagi
                            totPages={1}
                            currentPage={currPage}
                            pageClicked={(ele) => {
                                TokenOverview(ele);
                            }}
                            >
                            <ul>
                                {tagList.map((ele, ind) => {
                                return <li key={ele + ind}>{ele}</li>;
                                })}
                            </ul>
                        </CPagi>
                        </Col>
                    </Row>
                </Container>

            </section>
        
        </>
    );
}

export default Tokens;
