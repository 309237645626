import React, { useEffect, useState } from "react";
import {Link, useParams} from "react-router-dom";
import Moment from 'react-moment';
import {Container, Row, Col, ListGroup, Card, Pagination} from 'react-bootstrap';

import DataService from "../../services/data.service";
import CPagi from "../../components/Pagination";

const AddressDetail = () => {
    const [addressdetail, setAddress] = useState("");
    const [addresstxs, setAddressTxs] = useState([]);
    const [page, setPage] = useState("1");
    const [tagList, setTagList] = useState([]);
    const [currPage, setCurrPage] = useState(1);

    const {address} = useParams();

    // console.log('price :' + price)

    const AddressDetail = async () => {
        DataService.getAddressDetail(address)
        .then( response => {
            console.log("address: " + response.data.result);
            setAddress(response.data.result);
        })
        .catch(function (error) {
            if (error.response) {
                console.log(error.response.data.error);
            }
        });
    }

    const AddressTxs = async (page) => {
        setCurrPage(page);
        DataService.getAddressTxs(address, page)
        .then( response => {
            setAddressTxs(response.data.result);
        })
        .catch(function (error) {
            if (error.response) {
                console.log(error.response.data.error);
            }
        });
    }

    useEffect(() => {
        AddressDetail();
        AddressTxs(1);
    }, [setAddress, setAddressTxs]);

    return (
        <>
            
            <section className="section__wrapper" key={address}>
                <Container>
                    <Row md={8}>
                        <Col>
                            <h1 className="h1">Address Details</h1>
                        </Col>
                    </Row>
                    <Row md={8}>
                        <Col>
                            <Card>
                                <ListGroup variant="flush">
                                    <ListGroup.Item className="d-flex justify-content-between">Address <span>{address}</span></ListGroup.Item>
                                    <ListGroup.Item className="d-flex justify-content-between">Created <span>{addressdetail.height} {/* TODO: Created not active? */}</span></ListGroup.Item>
                                    <ListGroup.Item className="d-flex justify-content-between">Last active {/* TODO: Last Active not active? */} </ListGroup.Item>
                                    <ListGroup.Item className="d-flex justify-content-between">Received <span>{addressdetail.received} RPD</span> 
                                        {/* TODO: Received Does not work */}
                                    </ListGroup.Item>
                                    <ListGroup.Item className="d-flex justify-content-between">Sent <span>{addressdetail.sent} RPD</span>{/* TODO: Sent Does not work */}</ListGroup.Item>
                                    <ListGroup.Item className="d-flex justify-content-between">Balance <span>{addressdetail.balance} RPD</span></ListGroup.Item>
                                    {/* <ListGroup.Item className="d-flex justify-content-between">Value <span>${math} usd</span></ListGroup.Item> */}
                                </ListGroup>
                            </Card>
                        </Col>
                    </Row>

                    
                    {addresstxs.map((txs, index) => (
                    <Row key={index} md={8} className="mt-4">
                        <Col>
                            <Card>
                                <ListGroup variant="flush">
                                    <ListGroup.Item className="d-flex justify-content-between">
                                        Address Transactions
                                        <span><Moment unix date={txs.timestamp} format={'LLL'} /></span>
                                    </ListGroup.Item>
                                    <ListGroup.Item>
                                        <Row>
                                            <Col>
                                                <ListGroup variant="flush">
                                                    <ListGroup.Item><strong>From:</strong></ListGroup.Item>
                                                </ListGroup>
                                            
                                                {txs["inputs"].map((input, index) => (
                                                    <ListGroup key={index} variant="flush">
                                                    <ListGroup.Item>Address: <Link to={`/data/address/${input.address}`}>{input.address}</Link><br />
                                                    Amount: {input.amount} {input.currency}</ListGroup.Item>
                                                    </ListGroup>
                                                ))}
                                            </Col>
                                            <Col>
                                                <ListGroup variant="flush">
                                                    <ListGroup.Item><strong>To:</strong></ListGroup.Item>
                                                </ListGroup>
                                            
                                                {txs["outputs"].map((output, index) => (
                                                    <ListGroup key={index} variant="flush">
                                                        <ListGroup.Item>Address: <Link to={`/data/address/${output.address}`}>{output.address}</Link><br />
                                                        Amount: {output.amount} {output.currency}</ListGroup.Item>
                                                    </ListGroup>
                                                ))}
                                            </Col>
                                        </Row>
                                    </ListGroup.Item>
                                </ListGroup>
                            </Card>
                        </Col>
                    </Row>
                    ))}

                    

                    <Row>
                        <Col>
                            <CPagi
                                totPages={5}
                                currentPage={currPage}
                                pageClicked={(ele) => {
                                    AddressTxs(ele);
                                }}
                                >
                                <ul>
                                    {tagList.map((ele, ind) => {
                                    return <li key={ele + ind}>{ele}</li>;
                                    })}
                                </ul>
                            </CPagi>
                        </Col>
                    </Row>

                    {/* If token / coin tx
                    <Row md={8}>
                        <Col>
                            <Card>
                                    <ListGroup variant="flush">
                                        <ListGroup.Item className="d-flex justify-content-between">Token transfers <span><img alt="token logo" src="https://via.placeholder.com/25x25" width={25} height={25} />{' '} Token name (token amount) From (address)</span></ListGroup.Item>
                                    </ListGroup>
                            </Card>
                        </Col>
                    </Row> */}
                </Container>

            </section>
        
        </>
    );
}

export default AddressDetail;
