import React, { useState, useEffect } from "react";
import {Link, useParams} from "react-router-dom";
import {Container, Row, Col, Card, ListGroup} from 'react-bootstrap';

import DataService from "../../services/data.service";

const BlocksDetail = () => {
    const [block, setBlockDetail] = useState("");
    const [txs, setBlockTxs] = useState([]);

    const {blockhash} = useParams();

    const BlockDetail = async () => {
        DataService.getBlockDetail(blockhash)
        .then( response => {
            console.log(response.data.result);
            setBlockDetail(response.data.result);
        })
        .catch(function (error) {
            if (error.response) {
                console.log(error.response.data.error);
            }
        });

        DataService.getBlockTxs(blockhash)
        .then( response => {
            console.log("inputs: " + response.data.result);
            setBlockTxs(response.data.result);
        })
        .catch(function (error) {
            if (error.response) {
                console.log(error.response.data.error);
            }
        });
    }

    useEffect(() => {
        BlockDetail();
    }, [setBlockDetail]);

    return (
        <>
            
            <section className="section__wrapper" key={blockhash}>
                
                <Container>
                    <Row md={8}>
                        <Col>
                            <div className="d-flex align-items-center justify-content-between">
                                <h1 className="h1 ml-2">Block Details</h1>
                            </div>
                            <hr />
                        </Col>
                    </Row>

                    <Row md={8}>
                        <Col>
                            <Card>
                                    <ListGroup variant="flush">
                                        <ListGroup.Item className="d-flex justify-content-between">Height <span>{block.height}</span></ListGroup.Item>
                                        <ListGroup.Item className="d-flex justify-content-between">Timestamp <span>{block.timestamp}</span></ListGroup.Item>
                                        <ListGroup.Item className="d-flex justify-content-between">Block Hash <span>{block.blockhash}</span></ListGroup.Item>
                                        <ListGroup.Item className="d-flex justify-content-between">Merkle Root <span>{block.merkleroot}</span></ListGroup.Item>
                                        <ListGroup.Item className="d-flex justify-content-between">Size <span>{block.bits}</span></ListGroup.Item>
                                        <ListGroup.Item className="d-flex justify-content-between">Version <span>{block.version}</span></ListGroup.Item>
                                        <ListGroup.Item className="d-flex justify-content-between">Transactions <span>{block.tx}</span></ListGroup.Item>
                                    </ListGroup>
                            </Card>
                        </Col>
                    </Row>

                    <Row md={8} className="mt-4">
                        <Col>
                        {txs.map((txs, index) => (
                            <Card key={index}>
                                <ListGroup variant="flush">
                                    <ListGroup.Item>
                                        Transaction: {txs.txid}
                                    </ListGroup.Item>
                                    <ListGroup.Item>
                                        <Row>
                                            <Col>
                                                <ListGroup variant="flush">
                                                    <ListGroup.Item><strong>From:</strong></ListGroup.Item>
                                                </ListGroup>
                                            
                                                {txs["inputs"].map((input, index) => (
                                                    <ListGroup key={index} variant="flush">
                                                    <ListGroup.Item>Address: <Link to={`/data/address/${input.address}`} title="">{input.address}</Link><br />
                                                    Amount: {input.amount} {input.currency}</ListGroup.Item>
                                                    </ListGroup>
                                                ))}
                                            </Col>
                                            <Col>
                                                <ListGroup variant="flush">
                                                    <ListGroup.Item><strong>To:</strong></ListGroup.Item>
                                                </ListGroup>
                                            
                                                {txs["outputs"].map((output, index) => (
                                                    <ListGroup key={index} variant="flush">
                                                        <ListGroup.Item>Address: <Link to={`/data/address/${output.address}`} title="">{output.address}</Link><br />
                                                        Amount: {output.amount} {output.currency}</ListGroup.Item>
                                                    </ListGroup>
                                                ))}
                                            </Col>
                                        </Row>
                                    </ListGroup.Item>
                                </ListGroup>
                            </Card>
                        ))}
                        </Col>
                    </Row>
                </Container>

            </section>
        
        </>
    );
}

export default BlocksDetail;
