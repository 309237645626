import React from "react";
import { Link } from "react-router-dom";
import {Container, Row, Col, Button, Card} from 'react-bootstrap';

const RpdAddress = () => {
    return (
        <>
            
            <section className="section__wrapper">
                
                <Container>
                    <Row md={8}>
                        <Col>
                            <Card>
                                <Card.Body>
                                    <h1 class="h3 text-gray-800">Rapids Wallet Allocations</h1>
                                    <p>
                                        Below you can find our Rapids Network allocation addresses we use for our eco system.
                                    </p>
                                    <div class="mb-3">
                                        <h5>Distribution</h5>
                                        <blockquote>
                                            <Link to={'/data/address/RbhuafdAXBL9waNgoPC5jtWxnXnWiZchL7'} alt="Distribution Address">
                                                RbhuafdAXBL9waNgoPC5jtWxnXnWiZchL7
                                            </Link>
                                        </blockquote>
                                    </div>
                                    <div class="mb-3">
                                        <h5>Team</h5>
                                        <blockquote>
                                            <Link to={'/data/address/Rn44zwxNU4dBgu8Vy2P4AbBDoss5XwkS7C'} alt="Team Address">
                                                Rn44zwxNU4dBgu8Vy2P4AbBDoss5XwkS7C
                                            </Link>
                                        </blockquote>
                                    </div>
                                    <div class="mb-3">
                                        <h5>Development</h5>
                                        <blockquote>
                                            <Link to={'/data/address/Rem4KXa1Z4GTDa4MNp5AH862uVPUmm6LzX'} alt="Development Address">
                                                Rem4KXa1Z4GTDa4MNp5AH862uVPUmm6LzX
                                            </Link>
                                        </blockquote>
                                    </div>
                                    <div class="mb-3">
                                        <h5>Bridge</h5>
                                        <blockquote>
                                            <Link to={'/data/address/Ri6zuhyoNgxy6GwYjgyAwCSdp3LTnRRyyS'} alt="Bridge Address">
                                                Ri6zuhyoNgxy6GwYjgyAwCSdp3LTnRRyyS
                                            </Link>
                                        </blockquote>
                                    </div>
                                    <div class="mb-3">
                                        <h5>Bridge bRPD</h5>
                                        <blockquote>
                                            <a href={'https://bscscan.com/token/0x028c0C6D68dd326fc4d4850E41a3D2cdac27989c'} alt="Bridge bRPD" target={'_blank'}>
                                                0x028c0C6D68dd326fc4d4850E41a3D2cdac27989c
                                            </a>
                                        </blockquote>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>

            </section>
        
        </>
    );
}

export default RpdAddress;
