import React, {useState, useEffect} from "react";
import DataService from "../../services/data.service";
import {Card, Container, Row, Col, ListGroup, Pagination, Tabs, Tab} from 'react-bootstrap';
import {Link, useParams} from "react-router-dom";
import axios from "axios";
import AssetDetail from "../../components/AssetDetail/Detail";
import AssetHolders from "../../components/AssetDetail/Holders";
import AssetsTransactions from "../../components/AssetDetail/Transactions";
import AssetActivity from "../../components/AssetDetail/Activity";


const TokensDetail = () => {
    const [token, setTokenDetails] = useState([]);
    const [meta, setMetaData] = useState();
    const [holders, setHolders] = useState();
    const [socials, setTokenSocials] = useState([]);

    const {tokenname} = useParams();
    const assetType = 'tokens';
    const assetName = tokenname.toLowerCase();

    const TokenDetail = async () => {
        DataService.getTokenDetail(tokenname)
        .then( response => {
            setTokenDetails(response.data.result);
        })
        .catch(function (error) {
            if (error.response) {
                console.log(error.response.data.error);
            }
        });

        await axios.get(`https://raw.githubusercontent.com/RapidsOfficial/Assets/main/${assetType}/${tokenname.toLowerCase()}/info.json`)
        .then((res) =>  {
            console.log(res.data);
            setMetaData(res.data);
        })
        .catch((err) => {
            let message = typeof err.response !== "undefined" ? err.response.data.message : err.message;
            console.warn("error", message);

            // if (err.response !== "undefined") {
            //     setOCDNotExist(true);
            // }
        })
        .finally(console.log('Meta Data ready'));
    }


    useEffect(() => {
        TokenDetail();
    }, [setTokenDetails]);

    return (
        <>
            <section className="section__wrapper">

                <Container>
                    <Row md={8}>
                        <Col>
                            <div className="d-flex align-items-center">
                                

                                {/* {`https://raw.githubusercontent.com/RapidsOfficial/Assets/develop/internal/${token.name.toLowerCase()}/logo-80x80.png` !== null ? */}
                                {`https://raw.githubusercontent.com/RapidsOfficial/Assets/main/${assetType}/${tokenname.toLowerCase()}/logo-80x80.png` !== null ?
                                    <img src={`https://raw.githubusercontent.com/RapidsOfficial/Assets/main/${assetType}/${tokenname.toLowerCase()}/logo-80x80.png`} width={50} height={50} alt={token.name} />
                                    : ""
                                }
                                {' '}
                                <h1 className="h1 ml-2">{token.name} [{token.name}]</h1>
                            </div>
                            <hr />
                        </Col>
                    </Row>
                    <Row md={8}>
                        <Col md={6}>
                            <Card>
                                <Card.Body>
                                    <strong>Overview</strong>
                                </Card.Body>
                                <ListGroup variant="flush">
                                    <ListGroup.Item className="d-flex justify-content-between">Price <span>$ [can't calculate price] | [can;t calculate price in RPD] RPD</span></ListGroup.Item>
                                    <ListGroup.Item className="d-flex justify-content-between">Diluted Market Cap <span>$ [no price information]</span></ListGroup.Item>
                                    <ListGroup.Item className="d-flex justify-content-between">Holders <span>{token.holders}</span></ListGroup.Item>
                                    <ListGroup.Item className="d-flex justify-content-between">Transfers <span>[no transfer data]</span></ListGroup.Item>
                                </ListGroup>
                            </Card>
                        </Col>
                        <Col md={6}>
                            <Card>
                                <Card.Body>
                                    <strong>Rapids [RPD] Profile</strong>
                                </Card.Body>
                                <ListGroup variant="flush">
                                    <ListGroup.Item className="d-flex justify-content-between">Asset ID <span>[no asset hash = unique identifier]</span></ListGroup.Item>
                                    <ListGroup.Item className="d-flex justify-content-between">Decimals <span>[no divided information]</span></ListGroup.Item>
                                    <ListGroup.Item className="d-flex justify-content-between">Issuer <span>{token.issuer}</span></ListGroup.Item>

                                    <ListGroup.Item className="d-flex justify-content-between">
                                        Official Site
                                        <span>
                                            <a href={meta?.website} rel="noreferrer" title={''} target={'_blank'}>{meta?.website}</a>
                                        </span>
                                    </ListGroup.Item>
                                    <ListGroup.Item className="d-flex justify-content-between">
                                        Socials
                                        <span>
                                            {meta?.links?.map((site, index) => (
                                                <>
                                                    {site?.name === 'mail' && (<a className="mx-1" target={'_blank'} rel="noopener noreferrer" href={`${site?.url}`} title={site?.name}><img src={`img/mail.svg`} width={20} height={20} alt={'mail'} /></a>)}
                                                    {site?.name === 'facebook' ? <a className="mx-1" target={'_blank'} rel="noopener noreferrer" href={`${site?.url}`} title={site?.name}><img src={`img/facebook.svg`} width={20} height={20} alt={'facebook'} /></a> : ""}
                                                    {site?.name === 'twitter' ? <a className="mx-1" target={'_blank'} rel="noopener noreferrer" href={`${site?.url}`} title={site?.name}><img src={`img/twitter.svg`} width={20} height={20} alt={'twitter'} /></a> : ""}
                                                    {site?.name === 'bitcointalk' ? <a className="mx-1" target={'_blank'} rel="noopener noreferrer" href={`${site?.url}`} title={site?.name}><img src={`img/bitcointalk.svg`} width={20} height={20} alt={'bitcointalk'} /></a> : ""}
                                                    {site?.name === 'github' ? <a className="mx-1" target={'_blank'} rel="noopener noreferrer" href={`${site?.url}`} title={site?.name}><img src={`img/github.svg`} width={20} height={20} alt={'github'} /></a> : ""}
                                                    {site?.name === 'telegram' ? <a className="mx-1" target={'_blank'} rel="noopener noreferrer" href={`${site?.url}`} title={site?.name}><img src={`img/telegram.svg`} width={20} height={20} alt={'telegram'} /></a> : ""}
                                                    {site?.name === 'discord' ? <a className="mx-1" target={'_blank'} rel="noopener noreferrer" href={`${site?.url}`} title={site?.name}><img src={`img/discord.svg`} width={20} height={20} alt={'discord'} /></a> : ""}
                                                    {site?.name === 'whitepaper' ? <a className="mx-1" target={'_blank'} rel="noopener noreferrer" href={`${site?.url}`} title={site?.name}><img src={`img/whitepaper.svg`} width={20} height={20} alt={'whitepaper'} /></a> : ""}
                                                    {site?.name === 'medium' ? <a className="mx-1" target={'_blank'} rel="noopener noreferrer" href={`${site?.url}`} title={site?.name}><img src={`img/medium.svg`} width={20} height={20} alt={'medium'} /></a> : ""}
                                                    {site?.name === 'reddit' ? <a className="mx-1" target={'_blank'} rel="noopener noreferrer" href={`${site?.url}`} title={site?.name}><img src={`img/reddit.svg`} width={20} height={20} alt={'reddit'} /></a> : ""}
                                                </>
                                            ))}
                                        </span>
                                    </ListGroup.Item>
                                </ListGroup>
                            </Card>
                        </Col>
                    </Row>

                    <Row md={8} className="mt-5">
                        <Col>
                            <Tabs defaultActiveKey="details" id="uncontrolled-tab-example" className="mb-3">
                                <Tab eventKey="details" title="Details">
                                    <AssetDetail 
                                    description={meta?.description}
                                    branding={`https://raw.githubusercontent.com/RapidsOfficial/Assets/main/${assetType}/${tokenname.toLowerCase()}/logo-80x80.png`}
                                    issuer={token.issuer}
                                    supply={token.supply}
                                    name={token.name}  />
                                </Tab>
                                <Tab eventKey="holders" title="Holders">
                                    <AssetHolders supply={token.supply} />
                                </Tab>
                                <Tab eventKey="transactions" title="Transactions">
                                    <AssetsTransactions />
                                    
                                </Tab>
                            </Tabs>
                        </Col>
                    </Row>
                </Container>

            </section>

        </>
    );
}

export default TokensDetail;
