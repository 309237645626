import React from 'react';
import './App.scss';

import {
  BrowserRouter as Router,
  Routes,
  Route,
  useParams
} from "react-router-dom";

import Navigation from "./components/Navigation";
import Footer from "./components/Footer";

import Home from "./pages/Home";
import Marketplace from "./pages/Marketplace";
import Projects from "./pages/Projects";
import Events from "./pages/Events";

import Holders from "./pages/Holders";
import API from "./pages/API";
import About from "./pages/About";
import Advertise from "./pages/Advertise";

import DetailedPage from "./pages/DetailedPage";

import EventsDetail from "./pages/data/EventsDetail";

import Assets from "./pages/data/Assets";
// import AssetsDetail from "./pages/data/AssetsDetail";

import Tokens from "./pages/data/Tokens";
// import TokensDetail from "./pages/data/TokensDetail";

import Blocks from "./pages/data/Blocks";
import BlocksDetail from "./pages/data/BlocksDetail";

import Transactions from "./pages/data/Transactions";
import TransactionsDetail from "./pages/data/TransactionsDetail";

import Tickets from "./pages/data/Tickets";
import TicketsDetail from "./pages/data/TicketsDetail";

import Masternodes from "./pages/data/Masternodes";
import MasternodesDetail from "./pages/data/MasternodesDetail";
import AddressDetail from './pages/data/AddressDetail';
import TokensDetail from './pages/data/TokensDetail';
import RpdAddress from './pages/RpdAddress';
import PageSubscribe from './pages/PageSubscribe';

const App = () => {
  return (
    <Router>
      <Navigation />

      <Routes>
        <Route path={`/`} element={<Transactions />} />
        <Route path={`/collections`} element={<Assets />} />
        <Route path={`/marketplace/`} element={<Marketplace />} />
        <Route path={`/marketplace/activity/`} element={<Marketplace />} />
        <Route path={`/events/`} element={<Events />} />
        <Route path={`/holders/`} element={<Holders />} />
        <Route path={`/api/`} element={<API />} />
        <Route path={`/rapids-addresses/`} element={<RpdAddress />} />
        <Route path={`/about/`} element={<About />} />
        <Route path={`/advertise/`} element={<Advertise />} />

        <Route path={`/data/address/:address`} element={<AddressDetail />} />

        <Route path={`/data/nfts/`} element={<Assets />} />
        {/* <Route path={`/data/assets/0677bdb15bca389acb0251fed3a522aac016ede7`} element={<AssetsDetail />} /> */}
        <Route path={`/data/asset/0677bdb15bca389acb0251fed3a522aac016ede7`} element={<DetailedPage />} />

        <Route path={`/tokens/`} element={<Tokens />} />
        <Route path={`/data/token/:tokenname`} element={<TokensDetail />} />

        <Route path={`/data/blocks/`} element={<Blocks />} />
        <Route path={`/data/block/:blockhash`} element={<BlocksDetail />} />

        <Route path={`/data/transactions/`} element={<Transactions />} />
        <Route path={`/data/transaction/:txid`} element={<TransactionsDetail />} />

        <Route path={`/data/events/0677bdb15bca389acb0251fed3a522aac016ede7`} element={<EventsDetail />} />

        <Route path={`/data/tickets/`} element={<Tickets />} />
        <Route path={`/data/ticket/0677bdb15bca389acb0251fed3a522aac016ede7`} element={<TicketsDetail />} />

        <Route path={`/data/masternodes/`} element={<Masternodes />} />
        <Route path={`/data/address/0677bdb15bca389acb0251fed3a522aac016ede7`} element={<MasternodesDetail />} />

        <Route path={`/subscribe`} element={<PageSubscribe />} />
      </Routes>
      
      <Footer />
    </Router>
  );
}

export default App;
