import React, { useEffect, useState } from "react";
import {Link} from "react-router-dom";
import {Container, Row, Col, Table, Card } from 'react-bootstrap';

import DataService from "../../services/data.service";
import CPagi from "../../components/Pagination";

const Transactions = () => {
    const [transactions, setTxOverview] = useState([]);
    const [page, setPage] = useState(1);
    const [tagList, setTagList] = useState([]);
    const [currPage, setCurrPage] = useState(1);

    const TxOverview = async (page) => {
        setCurrPage(page);
        DataService.getTxOverview(page)
        .then( response => {
            // console.log(response.data.result);
            setTxOverview(response.data.result)
        })
        .catch(function (error) {
            if (error.response) {
                console.log(error.response.data.error);
            }
        });
    }

    useEffect(() => {
        TxOverview(1);
    }, [setTxOverview]);

    return (
        <>
            {/* {console.log(transactions)} */}
            <section className="section__wrapper">
                
                <Container>
                    <Row md={8}>
                        <Col>
                            <div className="d-flex align-items-center justify-content-between">
                                <h1 className="h1 ml-2">Transactions</h1>
                            </div>
                            <hr />
                        </Col>
                    </Row>
                    <Row md={8}>
                        <Col>
                            <Card>
                                <Table striped responsive hover>
                                    <thead>
                                        <tr>
                                            <th>Asset</th>
                                            <th>Hash</th>
                                            <th>Amount</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    
                                    {transactions && (
                                        transactions.map((data, index) => (
                                            <tr key={index}>
                                                <td>
                                                    
                                                    {/* {`https://raw.githubusercontent.com/RapidsOfficial/Assets/develop/internal/${data.token.toLowerCase()}/logo-80x80.png` ? (
                                                        <img src={`https://raw.githubusercontent.com/RapidsOfficial/Assets/develop/internal/${data.token.toLowerCase()}/logo-80x80.png`} width={25} height={25} alt={data.name} />
                                                    ) : ( */}
                                                        <img src={`https://raw.githubusercontent.com/RapidsOfficial/Assets/develop/internal/rpd/logo-80x80.png`} width={25} height={25} alt={data.name} />
                                                    {/* )} */}
                                                    {' '}
                                                    <Link to={`/data/token/${data.token}`} title={data.token}>
                                                        {data.token}
                                                    </Link>
                                                </td>
                                                <td>
                                                    <Link to={`/data/transaction/${data.txid}`} title="">
                                                        {data.txid}
                                                    </Link>
                                                </td>
                                                <td>{data.amount} RPD</td>
                                            </tr>
                                            
                                        ))
                                    )}
                                    </ tbody>
                                </Table>
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <CPagi
                                totPages={200}
                                currentPage={currPage}
                                pageClicked={(ele) => {
                                    TxOverview(ele);
                                }}
                                >
                                <ul>
                                    {tagList.map((ele, ind) => {
                                    return <li key={ele + ind}>{ele}</li>;
                                    })}
                                </ul>
                            </CPagi>
                        </Col>
                    </Row>
                </Container>

            </section>
        
        </>
    );
}

export default Transactions;
