import React from "react";
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Container from 'react-bootstrap/Container';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Search from "./Search";

import logo from '../assets/img/logo.svg';

const Navigation = () => {
    return (
        <>
            
            <Navbar bg="white" expand="lg">
                <Container fluid>
                    <Navbar.Brand href={`/`}>
                        <img width="50" height="50" src={`${logo}`} style={{marginRight: "15px"}} />

                        Rapids Explorer
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="navbarScroll" />
                    <Navbar.Collapse id="navbarScroll">
                    <Nav
                        className="me-auto my-2 my-lg-0"
                        style={{ maxHeight: '100px' }}
                        navbarScroll
                    >
                        <Nav.Link>Collections</Nav.Link>
                        <Nav.Link href={`/tokens`}>Tokens</Nav.Link>
                        <Nav.Link>Events</Nav.Link>
                        <Nav.Link>Marketplace</Nav.Link>

                        <NavDropdown title="Data" id="navbarScrollingDropdown">
                            <NavDropdown.Item href={`/data/nfts`}>NFTs</NavDropdown.Item>
                            <NavDropdown.Item href={`/data/blocks`}>Blocks</NavDropdown.Item>
                            <NavDropdown.Item href={`/data/transactions`}>Transactions</NavDropdown.Item>
                            <NavDropdown.Item href={`/data/masternodes`}>Masternodes</NavDropdown.Item>
                        </NavDropdown>
                    </Nav>
                    <Search />
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        
        </>
    );
}

export default Navigation;
