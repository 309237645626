import React, { useEffect, useState } from "react";
import {Container, Row, Col, Table, Card, Badge} from 'react-bootstrap';
import Moment from 'react-moment';
import 'moment-timezone';

import Pagination from 'react-bootstrap/Pagination';

import DataService from "../../services/data.service";
import CPagi from "../../components/Pagination";

const Masternodes = () => {
    const [masternodes, setMNOverview] = useState([]);
    const [activemn, setActiveMN] = useState([]);
    const [page, setPage] = useState("1");
    const [tagList, setTagList] = useState([]);
    const [currPage, setCurrPage] = useState(1);

    const MNOverview = async (page) => {
        setCurrPage(page);
        DataService.getMNOverview(page)
        .then( response => {
            // console.log(response.data.result);
            setMNOverview(response.data.result);
        })
        .catch(function (error) {
            if (error.response) {
                console.log(error.response.data.error);
            }
        });
    }

    const ActiveMN = async () => {
        DataService.getActiveMN()
        .then( response => {
            setActiveMN(response.data.result.reverse()[0].value)
        })
        .catch(function (error) {
            if (error.response) {
                console.log(error.response.data.error);
            }
        });
    }

    useEffect(() => {
        MNOverview(1);
        ActiveMN();
    }, [setMNOverview, setActiveMN]);

    return (
        <>
            
            <section className="section__wrapper">
                
                <Container>
                    <Row md={8}>
                        <Col>
                            <div className="d-flex align-items-center justify-content-between">
                                <h1 className="h1 ml-2">Masternodes</h1>
                                <h5 className="h5 ml-2"><Badge pill bg="success"></Badge> {activemn} active</h5>
                            </div>
                            <hr />
                        </Col>
                    </Row>

                    <Row md={8}>
                        <Col>
                            <Card>
                                <Table striped responsive hover>
                                    <thead>
                                        <tr>
                                            <th>Rank</th>
                                            <th>Address</th>
                                            <th>Status</th>
                                            <th>Last Paid</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    
                                    {masternodes.map((data, index) => (
                                            <tr key={index}>
                                                <td>{data.rank + 1}</td>
                                                <td><a href={`/data/address/${data.address}`}>{data.address}</a></td>
                                                <td>{data.status}</td>
                                                <td><Moment unix date={data.lastpaid} format={'LLL'} /></td>
                                            </tr>
                                    ))}
                                    </tbody>
                                </Table>
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <CPagi
                                totPages={5}
                                currentPage={currPage}
                                pageClicked={(ele) => {
                                    MNOverview(ele);
                                }}
                                >
                                <ul>
                                    {tagList.map((ele, ind) => {
                                    return <li key={ele + ind}>{ele}</li>;
                                    })}
                                </ul>
                            </CPagi>
                        </Col>
                    </Row>
                </Container>

            </section>
        
        </>
    );
}

export default Masternodes;
