import axios from "axios";

const API_URI = 'https://rapidsexplorer.io/v2/'
const GIT_URI = "https://raw.githubusercontent.com/RapidsOfficial/Assets/main/"

const getTxOverview = (page) => {
  return axios.get(`${API_URI}/transactions?page=${page}`);
};

const getTxDetail = (txhash) => {
  return axios.get(`${API_URI}/transaction/${txhash}`);
};

const getMNOverview = (page) => {
  return axios.get(`${API_URI}/masternodes?page=${page}&filter=ENABLED`);
};
const getActiveMN = () => {
  return axios.get(`${API_URI}/chart/masternodes?page=1`);
};

const getBlockOverview = (page) => {
  return axios.get(`${API_URI}/blocks?page=${page}`);
};

const getBlockDetail = (blockhash) => {
  return axios.get(`${API_URI}/block/${blockhash}`);
};

const getBlockTxs = (blockhash) => {
  return axios.get(`${API_URI}/block/${blockhash}/transactions?page=1`);
};

const getAddressDetail = (addresshash) => {
  return axios.get(`${API_URI}/balance/${addresshash}`);
};
const getAddressTxs = (addresshash, page) => {
  return axios.get(`${API_URI}/history/${addresshash}?page=${page}`);
};

const getTokenOverview = (page) => {
  return axios.get(`${API_URI}tokens?page=${page}`);
};

const getTokenDetail = (tokenname) => {
  return axios.get(`${API_URI}/token/${tokenname}`);
};

const getOCDTokenDetail = (assetName, assetType) => {
  return axios.get(`${GIT_URI}${assetType}/${assetName}/info.json`);
};

const getBlocksOverview = (page) => {
  return axios.get(`${API_URI}/blocks?page=${page}`);
};

const getNFTOverview = (page) => {
  return axios.get(`${API_URI}/nft?page=${page}`);
};

const exportedObjects = {
    getTxOverview,
    getTxDetail,
    getMNOverview,
    getActiveMN,
    getBlockOverview,
    getBlockDetail,
    getBlockTxs,
    getAddressDetail,
    getAddressTxs,
    getTokenOverview,
    getTokenDetail,
    getOCDTokenDetail,
    getBlocksOverview,
    getNFTOverview
}

export default exportedObjects;
