import React from "react";

const Events = () => {
    return (
        <>

            <div>Events works!</div>    
        
        </>
    );
}

export default Events;
