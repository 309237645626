import React from "react";
import Pages from "../components/Pagination";
import {Card, Container, Row, Col, Table, Badge, ListGroup} from 'react-bootstrap';

const data = [{
    assetName: 'Rapids Network',
    assetId: '0x1e2cdda8dd439e3953a6f43d1a2a7a4c3af39922',
    assetTicker: 'RPD',
    assetLogo: 'https://via.placeholder.com/80x80',
    assetPrice: '0.02',
    assetPriceInRPD: '1',
    dilutedMarketCap: '418.931',
    holders: '3',
    transfers: '80000',
    assetDecimals: '8',
    assetOfficialWebsite: 'https//:www.rapidsnetwork.io/',
    assetSocials: [
        {
            mail: 'corey@rapidsnetwork.io',
            medium: 'https://rapidsnetwork.medium.com/',
            reddit: 'https://www.reddit.com/r/RapidsNetwork/',
            facebook: 'https://facebook.com/rapidsnetwork',
            twitter: 'https://twitter.com/RapidsRPD',
            bitcointalk: 'https://bitcointalk.org/index.php?topic=5009962.0',
            github: 'https://github.com/RapidsOfficial',
            telegram: 'https://t.me/rapidsofficial',
            discord: 'https://discord.gg/Hrppjrf52Y',
            whitepaper: 'https://rapidsnetwork.io/whitepaper/',
        }
    ],
    assetHolders: [
        {
            rank: '1',
            address: '808a51c68ec18b7008ad383c2dfce061a77a983c8ed976a6733d17d3c56907e3',
            quantity: '54',
            percentage: '10',
        },
        {
            rank: '2',
            address: '808a51c68ec18b7008ad383c2dfce061a77a983c8ed976a6733d17d3c56907e2',
            quantity: '54',
            percentage: '10',
        },
        {
            rank: '3',
            address: '808a51c68ec18b7008ad383c2dfce061a77a983c8ed976a6733d17d3c56907e3',
            quantity: '27',
            percentage: '5',
        },
        {
            rank: '4',
            address: '808a51c68ec18b7008ad383c2dfce061a77a983c8ed976a6733d17d3c5690721',
            quantity: '27',
            percentage: '5',
        },
    ]
}]

const Holders = () => {
    return (
        <> 
            <section className="section__wrapper">
                
                <Container>
                    <Row md={8}>
                        <Col>
                            <div className="d-flex align-items-center">
                                <img src={data[0].assetLogo} width={50} height={50} alt={data[0].assetName + 'branding'} />
                                {' '}
                                <h1 className="h1 ml-2">{data[0].assetName} [{data[0].assetTicker}]</h1>
                            </div>
                            <hr />
                        </Col>
                    </Row>
                    <Row md={8}>
                        <Col md={6}>
                            <Card>
                                <Card.Body>
                                    <strong>Overview</strong>
                                </Card.Body>
                                <ListGroup variant="flush">
                                    <ListGroup.Item className="d-flex justify-content-between">Price <span>${data[0].assetPrice} | {data[0].assetPriceInRPD} RPD</span></ListGroup.Item>
                                    <ListGroup.Item className="d-flex justify-content-between">Diluted Market Cap <span>${data[0].dilutedMarketCap}</span></ListGroup.Item>
                                    <ListGroup.Item className="d-flex justify-content-between">Holders <span>{data[0].holders}</span></ListGroup.Item>
                                    <ListGroup.Item className="d-flex justify-content-between">Transfers <span>{data[0].transfers}</span></ListGroup.Item>
                                </ListGroup>
                            </Card>
                        </Col>
                        <Col md={6}>
                            <Card>
                                <Card.Body>
                                    <strong>Rapids [RPD] Profile</strong>
                                </Card.Body>
                                <ListGroup variant="flush">
                                    <ListGroup.Item className="d-flex justify-content-between">Asset ID <span>{data[0].assetId}</span></ListGroup.Item>
                                    <ListGroup.Item className="d-flex justify-content-between">Decimals <span>{data[0].assetDecimals}</span></ListGroup.Item>
                                    <ListGroup.Item className="d-flex justify-content-between">
                                        Official Site 
                                        <span>
                                            <a href={data[0].assetOfficialWebsite} rel="noreferrer" title={''} target={'_blank'}>{data[0].assetOfficialWebsite}</a>
                                        </span>
                                    </ListGroup.Item>
                                    <ListGroup.Item className="d-flex justify-content-between">
                                        Socials 
                                        <span>
                                            {data[0].assetSocials[0].mail ? <a href={data[0].assetSocials[0].mail} title={''} target={'_blank'} rel="noreferrer"><img src={`img/mail.svg`} width={20} height={20} alt={'mail'} /></a> : <></>}
                                            {' '}
                                            {data[0].assetSocials[0].medium ? <a href={data[0].assetSocials[0].medium} title={''} target={'_blank'} rel="noreferrer"><img src={`img/medium.svg`} width={20} height={20} alt={'mail'} /></a> : ''}
                                            {' '}
                                            {data[0].assetSocials[0].reddit ? <a href={data[0].assetSocials[0].reddit} title={''} target={'_blank'} rel="noreferrer"><img src={`img/reddit.svg`} width={20} height={20} alt={'mail'} /></a> : ''}
                                            {' '}
                                            {data[0].assetSocials[0].facebook ? <a href={data[0].assetSocials[0].facebook} title={''} target={'_blank'} rel="noreferrer"><img src={`img/facebook.svg`} width={20} height={20} alt={'mail'} /></a> : ''}
                                            {' '}
                                            {data[0].assetSocials[0].twitter ? <a href={data[0].assetSocials[0].twitter} title={''} target={'_blank'} rel="noreferrer"><img src={`img/twitter.svg`} width={20} height={20} alt={'mail'} /></a> : ''}
                                            {' '}
                                            {data[0].assetSocials[0].bitcointalk ? <a href={data[0].assetSocials[0].bitcointalk} title={''} target={'_blank'} rel="noreferrer"><img src={`img/bitcoin.svg`} width={20} height={20} alt={'mail'} /></a> : ''}
                                            {' '}
                                            {data[0].assetSocials[0].github ? <a href={data[0].assetSocials[0].github} title={''} target={'_blank'} rel="noreferrer"><img src={`img/github.svg`} width={20} height={20} alt={'mail'} /></a> : ''}
                                            {' '}
                                            {data[0].assetSocials[0].telegram ? <a href={data[0].assetSocials[0].telegram} title={''} target={'_blank'} rel="noreferrer"><img src={`img/telegram.svg`} width={20} height={20} alt={'mail'} /></a> : ''}
                                            {' '}
                                            {data[0].assetSocials[0].discord ? <a href={data[0].assetSocials[0].discord} title={''} target={'_blank'} rel="noreferrer"><img src={`img/discord.svg`} width={20} height={20} alt={'mail'} /></a> : ''}
                                            {' '}
                                            {data[0].assetSocials[0].whitepaper ? <a href={data[0].assetSocials[0].whitepaper} title={''} target={'_blank'} rel="noreferrer"><img src={`img/whitepaper.svg`} width={20} height={20} alt={'mail'} /></a> : ''}
                                            {' '}
                                        </span>
                                    </ListGroup.Item>
                                </ListGroup>
                            </Card>
                        </Col>
                    </Row>

                    <Row md={8} className="mt-5">
                        <Col>
                            <h2 className="h2">Holders</h2>
                            <hr />
                            <Card>
                                <Table striped responsive hover>
                                    <thead>
                                        <tr>
                                            <th>Rank</th>
                                            <th>Address</th>
                                            <th>Quantity</th>
                                            <th>Percentage</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {data.map((holder, index) => (
                                            (holder.assetHolders || []).map(holders =>
                                                <tr>
                                                    <td>{holders.rank}</td>
                                                    <td>
                                                        <a href={'https://localhost:3000/data/blocks/' + holders.address} className="mr-2">{holders.address}</a>
                                                        {
                                                            holders.address === '808a51c68ec18b7008ad383c2dfce061a77a983c8ed976a6733d17d3c56907e2' ?
                                                            <Badge pill bg="info">bridge</Badge> :
                                                            holders.address === '808a51c68ec18b7008ad383c2dfce061a77a983c8ed976a6733d17d3c5690721' ?
                                                            <Badge pill bg="info">dev</Badge> :
                                                            ''
                                                        }
                                                    </td>
                                                    <td>{holders.quantity}</td>
                                                    <td>{holders.percentage}</td>
                                                </tr>
                                            )
                                        ))}
                                    </tbody>
                                </Table>
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Pages />
                        </Col>
                    </Row>
                </Container>

            </section>
        
        </>
    );
}

export default Holders;
