import React, {useState, useEffect} from "react";
import {Link, useParams} from "react-router-dom";
import axios from "axios";

import {Row, Col, Table, Card} from 'react-bootstrap';
import CPagi from "../Pagination";

const AssetHolders = (props) => {
    const [data, setHolders] = useState([]);
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(1);
    const [tagList, setTagList] = useState([]);
    const [currPage, setCurrPage] = useState(1);

    const {tokenname} = useParams();


    const getAssetHolders = (page) => {
        setCurrPage(page);        

        axios.get(`https://rapidsexplorer.io/v2/holders/${tokenname}?page=${page}`)
        .then((res) =>  {
            console.log(res.data.result);
            setHolders(res.data.result);
        })
        .catch((err) => {
            let message = typeof err.response !== "undefined" ? err.response.data.message : err.message;
            console.warn("error", message);
        })
        .finally(
            setLoading( false )
        );
    }


    useEffect(() => {
        setLoading( true );
        getAssetHolders(1)
        
        
    }, [tokenname, setLoading]);
    
        return (
        <>
            <Row md={8} className="mt-1">
                <Col>
                    {loading ? <div>Loading...</div> : (
                        <Card>
                            <Table striped responsive hover>
                                <thead>
                                    <tr>
                                        <th>Rank</th>
                                        <th>Address</th>
                                        <th>Quantity</th>
                                        <th>Percentage</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data.map((holder, index) => (
                                        <tr key={index}>
                                            <td>{index}</td>
                                            <td>
                                                <Link to={`/data/address/${holder.address}`} >{holder.address}</Link>
                                            </td>
                                            <td>{holder.balance}</td>
                                            <td>{holder.balance / props.supply * 100}%</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </Card>
                    )}
                </Col>
            </Row>

            <Row>
                <Col>
                
                    <CPagi
                        totPages={200}
                        currentPage={currPage}
                        pageClicked={(ele) => {
                            getAssetHolders(ele);
                        }}
                        >
                        <ul>
                            {tagList.map((ele, ind) => {
                            return <li key={ele + ind}>{ele}</li>;
                            })}
                        </ul>
                    </CPagi>
                </Col>
            </Row>
        </>
        );
}

export default AssetHolders;
