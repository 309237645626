import React from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Pages from "../../components/Pagination";
import DataTable from "../../components/DataTables";

const Tickets = () => {
    return (
        <>
            
            <section className="section__wrapper">
                
                <Container>
                    <Row md={8}>
                        <DataTable />

                        <Pages />
                    </Row>
                </Container>

            </section>
        
        </>
    );
}

export default Tickets;
