import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { Container, Row, Col, ListGroup, Card } from 'react-bootstrap';

import DataService from "../../services/data.service";
import axios from "axios";
import Moment from "react-moment";

const TransactionsDetail = () => {
    const [transaction, setTxDetail] = useState([]);
    const [amount, setTxAmount] = useState("");
    const [inputs, setInputs] = useState([""]);
    const [outputs, setOutputs] = useState([]);
    const [price, setPrice] = useState("");

    const { txid } = useParams();

    const tokenPrice = amount * price;
    const math = Number((tokenPrice).toFixed(2));

    const TxDetail = async () => {
        DataService.getTxDetail(txid)
            .then(response => {
                setTxDetail(response.data.result);
                setTxAmount(response.data.result.amount);

                setInputs(response.data.result.inputs)
                setOutputs(response.data.result.outputs)
            })
            .catch(function (error) {
                if (error.response) {
                    console.log(error.response.data.error);
                }
            });
    }

    const priceCalc = () => {
        axios.get('https://api.coingecko.com/api/v3/simple/price?ids=rapids&vs_currencies=usd')
            .then((response) => {
                setPrice(response.data.rapids["usd"]);
            })
    }

    useEffect(() => {
        TxDetail();
        priceCalc();
    }, [setTxDetail, setTxAmount]);

    return (
        <>

            <section className="section__wrapper">
                <Container>
                    <Row md={8}>
                        <Col>
                            <div className="d-flex align-items-center justify-content-between">
                                <h1 className="h1 ml-2">Transaction Details</h1>
                            </div>
                            <hr />
                        </Col>
                    </Row>
                    <Row md={8}>
                        <Col>
                            <Card>
                                <ListGroup variant="flush">
                                    <ListGroup.Item className="d-flex justify-content-between">Hash <span>{txid}</span></ListGroup.Item>
                                    <ListGroup.Item className="d-flex justify-content-between">Height <span>{transaction.height}</span></ListGroup.Item>
                                    <ListGroup.Item className="d-flex justify-content-between">Status {transaction.confirmations ? <span><span className="text-succes">Success </span> ({transaction.confirmations} confirmations)</span> : <></>}</ListGroup.Item>
                                    <ListGroup.Item className="d-flex justify-content-between">Time <span><Moment unix date={transaction.amount} format={'LLL'} /></span></ListGroup.Item>
                                    {/* <ListGroup.Item className="d-flex justify-content-between">To <span><Link to={`/data/address/${transaction["outputs"].address}`}>{transaction["outputs"].address}</Link></span></ListGroup.Item> */}
                                    <ListGroup.Item className="d-flex justify-content-between">Amount <span>{transaction.amount} RPD</span></ListGroup.Item>
                                    <ListGroup.Item className="d-flex justify-content-between">Value <span>${math} usd</span></ListGroup.Item>
                                </ListGroup>
                            </Card>
                        </Col>
                    </Row>


                    {/* {transaction.map((txs, index) => ( */}
                    <Row md={8} className="mt-4">
                        <Col>
                            <Card>
                                <ListGroup variant="flush">
                                    <ListGroup.Item>
                                        Transaction Details
                                    </ListGroup.Item>
                                    <ListGroup.Item>

                                        <Row>
                                            <Col>
                                                {inputs.map((input, index) => (
                                                    <ListGroup key={index} variant="flush">
                                                        <ListGroup.Item>Address: <Link to={`/data/address/${input.address}`}>{input.address}</Link><br />
                                                            Amount: {input.amount} {input.currency}</ListGroup.Item>
                                                    </ListGroup>
                                                ))}
                                            </Col>
                                            <Col>
                                                {outputs.map((output, index) => (
                                                    <ListGroup key={index} variant="flush">
                                                        <ListGroup.Item>Address: <Link to={`/data/address/${output.address}`}>{output.address}</Link><br />
                                                            Amount: {output.amount} {output.currency}</ListGroup.Item>
                                                    </ListGroup>
                                                ))}
                                            </Col>
                                        </Row>

                                    </ListGroup.Item>
                                </ListGroup>
                            </Card>
                        </Col>
                    </Row>
                </Container>

            </section>

        </>
    );
}

export default TransactionsDetail;
