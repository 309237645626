import React from "react";
import Table from 'react-bootstrap/Table'

const data = [
    {
        transaction: '0677bdb15bca389acb0251fed3a522aac016ede7',
        asset: 'RPD',
        assetLogo: 'https://via.placeholder.com/50x50',
        from: '0x8445165437cb63affb2f143378d0ec63e0249582',
        to: '0xf52c6c26c47899e01fb7dcd1f0f80e2e6546b53d',
        time: '2022-01-20 18:31:30'
    },
    {
        transaction: '0677bdb15bca389acb0251fed3a522aac016ede7',
        asset: 'RPD',
        assetLogo: 'https://via.placeholder.com/50x50',
        from: '0x8445165437cb63affb2f143378d0ec63e0249582',
        to: '0xf52c6c26c47899e01fb7dcd1f0f80e2e6546b53d',
        time: '2022-01-20 18:31:30'
    },
    {
        transaction: '0677bdb15bca389acb0251fed3a522aac016ede7',
        asset: 'RPD',
        assetLogo: 'https://via.placeholder.com/50x50',
        from: '0x8445165437cb63affb2f143378d0ec63e0249582',
        to: '0xf52c6c26c47899e01fb7dcd1f0f80e2e6546b53d',
        time: '2022-01-20 18:31:30'
    },
    {
        transaction: '0677bdb15bca389acb0251fed3a522aac016ede7',
        asset: 'RPD',
        assetLogo: 'https://via.placeholder.com/50x50',
        from: '0x8445165437cb63affb2f143378d0ec63e0249582',
        to: '0xf52c6c26c47899e01fb7dcd1f0f80e2e6546b53d',
        time: '2022-01-20 18:31:30'
    },
]

const DataTable = () => {
    return (
        <>
            
            <Table striped responsive hover>
                <thead>
                    <tr>
                        <th>Transaction</th>
                        <th>Asset</th>
                        <th>Time</th>
                        <th>From</th>
                        <th>To</th>
                    </tr>
                </thead>
                <tbody>
                   
                {data.map((data, index) => (
                        <tr>
                            <td><a href={`data/transaction` + data.transaction}><span style={{maxWidth: '150px'}} className="d-inline-block text-truncate">{data.transaction}</span></a></td>
                            <td>{data.asset} <img src={data.assetLogo} width="25" height="25" alt={data.asset} /></td>
                            <td>{data.time}</td>
                            <td><a href={'https://localhost:3000/data/address/' + data.from}>{data.from}</a></td>
                            <td><a href={'https://localhost:3000/data/address/' + data.to}>{data.to}</a></td>
                        </tr>
                    
                ))}
                </tbody>
            </Table>
        
        </>
    );
}

export default DataTable;


