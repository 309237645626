import React from "react";
import {Container, Row, Col, Button, Card} from 'react-bootstrap';

const API = () => {
    return (
        <>
            
            <section className="section__wrapper">
                
                <Container>
                    <Row md={8}>
                        <Col>
                            <Card>
                                <Card.Body>
                                    <h1 class="h3 text-gray-800">API Documentation</h1>
                                    <p>
                                        This is documentation for legacy API backported from Bulwark.
                                    </p>
                                    <div class="mb-3">
                                        <Button onClick={() => {navigator.clipboard.writeText('/api/sendrawtransaction')}}>
                                            <img src={`img/copy.svg`} width={20} height={20} alt={'Copy'} />
                                        </Button>
                                        <b>/api/sendrawtransaction</b> <span class="badge bg-secondary">POST</span>
                                        <div>
                                            Broadcasts a raw transaction on the network. POST a "rawtx" body parameter to get one of three possible results: <code>raw, decoded, error</code> Use the optional "?decrypt=1" in GET to get decoded result.
                                        </div>
                                    </div>
                                    <div class="mb-3">
                                        <Button onClick={() => {navigator.clipboard.writeText('/api/address/RburnXXXXXXXXXXXXXXXXXXXXXXXWrcyQK')}}>
                                            <img src={`img/copy.svg`} width={20} height={20} alt={'Copy'} />
                                        </Button>
                                        <b>/api/address/[address]</b> <span class="badge bg-secondary">GET</span>
                                        <div>
                                            Returns information for given address.
                                        </div>
                                    </div>
                                    <div class="mb-3">
                                        <Button onClick={() => {navigator.clipboard.writeText('/api/block/3485880f812b81ab588579bdb069bb13bdc5ad3ceada19641ca254dd7c5e4686')}}>
                                            <img src={`img/copy.svg`} width={20} height={20} alt={'Copy'} />
                                        </Button>
                                        <b>/api/block/[blockhash][height]</b> <span class="badge bg-secondary">GET</span>
                                        <div>
                                            Returns block information for the given hash or height.
                                        </div>
                                    </div>
                                    <div class="mb-3">
                                        <Button onClick={() => {navigator.clipboard.writeText('/api/masternodecount')}}>
                                            <img src={`img/copy.svg`} width={20} height={20} alt={'Copy'} />
                                        </Button>
                                        <b>/api/masternodecount</b> <span class="badge bg-secondary">GET</span>
                                        <div>
                                            Returns masternodes enabled and total counts.
                                        </div>
                                    </div>
                                    <div class="mb-3">
                                        <Button onClick={() => {navigator.clipboard.writeText('/api/peer')}}>
                                            <img src={`img/copy.svg`} width={20} height={20} alt={'Copy'} />
                                        </Button>
                                        <b>/api/peer</b> <span class="badge bg-secondary">GET</span>
                                        <div>
                                            Returns peer information.
                                        </div>
                                    </div>
                                    <div class="mb-3">
                                        <Button  onClick={() => {navigator.clipboard.writeText('/api/supply')}}>
                                            <img src={`img/copy.svg`} width={20} height={20} alt={'Copy'} />
                                        </Button>
                                        <b>/api/supply</b> <span class="badge bg-secondary">GET</span>
                                        <div>
                                            Returns circulating and total supply information.
                                            <a href="https://github.com/coincheckup/crypto-supplies">
                                                https://github.com/coincheckup/crypto-supplies
                                            </a>
                                        </div>
                                    </div>
                                    <div class="mb-3">
                                        <Button onClick={() => {navigator.clipboard.writeText('/api/tx/latest')}}>
                                            <img src={`img/copy.svg`} width={20} height={20} alt={'Copy'} />
                                        </Button>
                                        <b>/api/tx/latest</b> <span class="badge bg-secondary">GET</span>
                                        <div>
                                            Returns latest transaction information.
                                        </div>
                                    </div>
                                    <div class="mb-3">
                                        <Button onClick={() => {navigator.clipboard.writeText('/api/tx/094e1a19549054812eead969c70ab1bbf565bc2bd7a475273c037039a5bf6e24')}}>
                                            <img src={`img/copy.svg`} width={20} height={20} alt={'Copy'} />
                                        </Button>
                                        <b>/api/tx/[txid]</b> <span class="badge bg-secondary">GET</span>
                                        <div>
                                            Returns information for the given transaction.
                                        </div>
                                    </div>
                                    <div class="mb-3">
                                        <Button onClick={() => {navigator.clipboard.writeText('/api/getdifficulty')}}>
                                            <img src={`img/copy.svg`} width={20} height={20} alt={'Copy'} />
                                        </Button>
                                        <b>/api/getdifficulty</b> <span class="badge bg-secondary">GET</span>
                                        <div>
                                            Returns the current difficulty.
                                        </div>
                                    </div>
                                    <div class="mb-3">
                                        <Button onClick={() => {navigator.clipboard.writeText('/api/getblockcount')}}>
                                            <img src={`img/copy.svg`} width={20} height={20} alt={'Copy'} />
                                        </Button>
                                        <b>/api/getblockcount</b> <span class="badge bg-secondary">GET</span>
                                        <div>
                                            Returns the current block index.
                                        </div>
                                    </div>
                                    <div class="mb-3">
                                        <Button onClick={() => {navigator.clipboard.writeText('/ext/getmoneysupply')}}>
                                            <img src={`img/copy.svg`} width={20} height={20} alt={'Copy'} />
                                        </Button>
                                        <b>/ext/getmoneysupply</b> <span class="badge bg-secondary">GET</span>
                                        <div>
                                            Returns the current money supply.
                                        </div>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>

            </section>
        
        </>
    );
}

export default API;
