import React, {useState, useEffect} from "react";
import {Link, useParams} from "react-router-dom";
import axios from "axios";
import Moment from 'react-moment';

import truncateHash from "../../utils/truncateHash";

import {Row, Col, Card, Table} from 'react-bootstrap';
import CPagi from "../Pagination";

const AssetsTransactions = () => {
    const [data, setTransactions] = useState([]);
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(1);
    const [tagList, setTagList] = useState([]);
    const [currPage, setCurrPage] = useState(1);

    const {tokenname} = useParams();

    const getAssetTransaction = (page) => {
        setCurrPage(page);        
        axios.get(`https://rapidsexplorer.io/v2/token/${tokenname}/transfers?page=${page}`)
        .then((res) =>  {
            console.log(res.data.result);
            setTransactions(res.data.result);
        })
        .catch((err) => {
            let message = typeof err.response !== "undefined" ? err.response.data.message : err.message;
            console.warn("error", message);
        })
        .finally(
            setLoading( false )
        );
    }

    useEffect(() => {
        setLoading( true );
        getAssetTransaction(1);
    }, [tokenname, setLoading]);

    return (
        <>
            <Row md={8}>
                <Col>
                    {!data ? (
                        <div>Loading ...</div>
                    ) : (
                        <Card>
                            <Table striped responsive hover>
                                <thead>
                                    <tr>
                                        <th>Transaction</th>
                                        <th>Time</th>
                                        <th>From</th>
                                        <th>To</th>
                                    </tr>
                                </thead>
                                <tbody>
                                
                                {data.map((data, index) => (
                                    <tr key={index}>
                                        <td><Link to={`/data/transaction/${data.transaction}`}>
                                            {truncateHash(data.transaction)}
                                            </Link></td>
                                        <td><Moment unix date={data.timestamp} format={'LLL'} /></td>
                                        <td><Link to={`/data/address/${data.sender}`}>
                                            {truncateHash(data.sender)}</Link></td>
                                        <td><Link to={`/data/address/${data.receiver}`}>
                                            {truncateHash(data.receiver)}
                                            </Link></td>
                                    </tr>
                                ))}
                                </tbody>
                            </Table>
                        </Card>
                    )}
                </Col>
            </Row>

            <Row>
                <Col>
                
                    <CPagi
                        totPages={200}
                        currentPage={currPage}
                        pageClicked={(ele) => {
                            getAssetTransaction(ele);
                        }}
                        >
                        <ul>
                            {tagList.map((ele, ind) => {
                            return <li key={ele + ind}>{ele}</li>;
                            })}
                        </ul>
                    </CPagi>
                </Col>
            </Row>
        
        </>
    );
}

export default AssetsTransactions;
