import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {Form, Badge} from 'react-bootstrap';

import '../assets/styles/search.css';

const Search = () => {
    const [loading, setLoading] = useState(true);
    const [suggestion, setSuggestion] = useState('');
    const [data, setResult] = useState('');
    
    const searchHandler = async (e) => {
        console.log(suggestion);

        if(data > !1) {
            await axios.get(`https://rapidsexplorer.io/v2/search?query=${suggestion}`)
            .then((res) => {
                console.log(res.data.result);
                setResult(res.data.result);
            })
            .finally(
                setLoading(false)
            );
        }
    } 
    
      useEffect(() => {
        searchHandler();
      }, [suggestion]);
    
      return (
        <Form className="search__function">
            <Form.Control
            className="me-2 search__field"
            type="search"
            placeholder="Search"
            aria-label="Search"
            value={suggestion}
            onChange={(e) => {setSuggestion(e.target.value); searchHandler()}}
            />
            {data ? (
                <div className="search__list">
                    <ul className="list-unstyled">
                        {data.map((d, i) => (
                            <li key={i} className="search__result">
                                {d.type === 'transaction' ? (
                                    <span>
                                        <Link to={`/data/transaction/${d.result}`} onClick={() => {setSuggestion('')}}>{d.result}</Link> <span><Badge bg="primary">transaction</Badge></span>
                                    </span>
                                ) : d.type === 'address' ? (
                                    <span>
                                        <Link to={`/data/address/${d.result}`} onClick={() => {setSuggestion('')}}>{d.result}</Link> <span><Badge bg="primary">address</Badge></span>
                                    </span>
                                ) : d.type === 'block' ? (
                                    <span>
                                        <Link to={`/data/block/${d.result}`} onClick={() => {setSuggestion('')}}>{d.result}</Link> <span><Badge bg="primary">block</Badge></span>
                                    </span>
                                ) : d.type === 'nft' ? (
                                    <span>
                                        <Link to={`/data/nft/${d.result}`} onClick={() => {setSuggestion('')}}>{d.result}</Link> <span><Badge bg="primary">nft</Badge></span>
                                    </span>
                                ) : d.type === 'collection' ? (
                                    <span>
                                        <Link to={`/data/collection/${d.result}`} onClick={() => {setSuggestion('')}}>{d.result}</Link> <span><Badge bg="primary">collection</Badge></span>
                                    </span>
                                ) : (
                                    <span>
                                        <Link to={`/data/token/${d.result}`} onClick={() => {setSuggestion('')}}>{d.result}</Link> <span><Badge bg="primary">token</Badge></span>
                                    </span>
                                )}
                            </li>
                        ))}
                    </ul>
                </div>
            ) : ''}
        </Form>
      );
}

export default Search;
