import React, { useEffect, useState } from "react";
import {Link} from "react-router-dom";
import {Container, Row, Col, Table, Card} from 'react-bootstrap';
import CPagi from "../../components/Pagination";
import Moment from 'react-moment';
import 'moment-timezone';

import DataService from "../../services/data.service";

const Blocks = () => {
    const [blocks, setBlockOverview] = useState([]);
    const [loading, setLoading] = useState(true);
    const [page, setPage] = useState("1");
    const [tagList, setTagList] = useState([]);
    const [currPage, setCurrPage] = useState(1);

    const BlockOverview = async (page) => {
        setCurrPage(page);
        setLoading(true);

        DataService.getBlockOverview(page)
        .then( response => {
            setBlockOverview(response.data.result)
        })
        .catch(function (error) {
            if (error.response) {
                console.log(error.response.data.error);
            }
        })
        .finally(
            setLoading( false )
        );
    }

    useEffect(() => {
        BlockOverview(1);
    }, [setBlockOverview, setLoading]);

    console.log(loading)

    return (
        <>
            
            <section className="section__wrapper">
                
                <Container>
                    <Row md={8}>
                        <Col>
                            <div className="d-flex align-items-center justify-content-between">
                                <h1 className="h1 ml-2">Blocks</h1>
                            </div>
                            <hr />
                        </Col>
                    </Row>

                    <Row md={8}>
                        <Col>
                            {loading ? <div>Loading...</div> : (
                                <Card>
                                    <Table striped responsive hover>
                                        <thead>
                                            <tr>
                                                <th>Block</th>
                                                <th>Hash</th>
                                                <th>Transactions</th>
                                                <th>Time</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                        
                                        {blocks.map((data, index) => (
                                            <tr key={index}>
                                                    <td>{data.height}</td>
                                                    <td><Link to={`/data/block/${data.blockhash}`} title="">{data.blockhash}</Link></td>
                                                    <td>{data.tx}</td>
                                                    {console.log(data.timestamp)}
                                                    <td><Moment unix date={data.timestamp} format={'LLL'} /></td>
                                                </tr>
                                            
                                            ))}
                                        </tbody>
                                    </Table>
                                </Card>
                            )}
                        </Col>
                    </Row>

                    <Row>
                        <Col>
                            <CPagi
                                totPages={200}
                                currentPage={currPage}
                                pageClicked={(ele) => {
                                    BlockOverview(ele);
                                }}
                                >
                                <ul>
                                    {tagList.map((ele, ind) => {
                                    return <li key={ele + ind}>{ele}</li>;
                                    })}
                                </ul>
                            </CPagi>
                        </Col>
                    </Row>
                </Container>

            </section>
        
        </>
    );
}

export default Blocks;
