import React from "react";
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import {Form, Button} from 'react-bootstrap/'

import mail from '../assets/img/mail.svg';
import medium from '../assets/img/medium.svg';
import reddit from '../assets/img/reddit.svg';
import facebook from '../assets/img/facebook.svg';
import twitter from '../assets/img/twitter.svg';
import bitcoin from '../assets/img/bitcoin.svg';
import github from '../assets/img/github.svg';
import telegram from '../assets/img/telegram.svg';
import discord from '../assets/img/discord.svg';


const Footer = () => {
    return (
        <>
            
            <footer className="bg_custom_green">

                <Container>
                    <footer className="py-5">
                        <Row>
                            {/* <Col md={2}>
                                <h5>Main</h5>
                                <ul className="nav flex-column">
                                    <li className="nav-item mb-2"><a href={'/projects'} className="nav-link p-0 text-muted">Projects</a></li>
                                    <li className="nav-item mb-2"><a href={'/events'} className="nav-link p-0 text-muted">Events</a></li>
                                    <li className="nav-item mb-2"><a href={'/marketplace'} className="nav-link p-0 text-muted">Marketplace</a></li>
                                </ul>
                            </Col> */}

                            <Col md={2}>
                                <h5>ASSETS</h5>
                                <ul className="nav flex-column">
                                    {/* <li className="nav-item mb-2"><a href={'/data/assets'} className="nav-link p-0 text-muted">Assets</a></li> */}
                                    <li className="nav-item mb-2"><a href={'/tokens'} className="nav-link p-0 text-muted">Tokens</a></li>
                                    {/* <li className="nav-item mb-2"><a href={'/data/events'} className="nav-link p-0 text-muted">Events</a></li> */}
                                    <li className="nav-item mb-2"><a href={'/blocks'} className="nav-link p-0 text-muted">Blocks</a></li>
                                    <li className="nav-item mb-2"><a href={'/transactions'} className="nav-link p-0 text-muted">Transactions</a></li>
                                </ul>
                            </Col>

                            <Col md={2}>
                                <h5>RPD</h5>
                                <ul className="nav flex-column">
                                    <li className="nav-item mb-2"><a href={'/data/masternodes'} className="nav-link p-0 text-muted">Masternodes</a></li>
                                    {/* <li className="nav-item mb-2"><a href={'/holders'} className="nav-link p-0 text-muted">Holders</a></li> */}
                                    <li className="nav-item mb-2"><a href={'/api'} className="nav-link p-0 text-muted">API</a></li>
                                    <li className="nav-item mb-2"><a href={'/rapids-addresses'} className="nav-link p-0 text-muted">RPD addresses</a></li>
                                    {/* <li className="nav-item mb-2"><a href={'https://rapidsnetwork.io/contact'} className="nav-link p-0 text-muted">Advertise</a></li> */}
                                </ul>
                            </Col>

                            {/* <Col md={4}>
                                <form data-nordpass-autofill="identity" data-np-checked="1">
                                <h5>Subscribe to our newsletter</h5>
                                    <p>Monthly digest of whats new and exciting from us.</p>
                                    <div className="d-flex w-100 gap-2">
                                        <Form.Label className="visually-hidden">Email address</Form.Label>
                                        <Form.Control id="newsletter1" type="text" className="form-control" placeholder="Email address" data-np-checked="1" data-nordpass-autofill="identity_email" data-nordpass-uid="a7egigylsub" autoComplete="off" />
                                        <Button variant="primary">Subscribe</Button>
                                    </div>
                                </form>
                            </Col> */}
                        </Row>

                        <Row className="d-flex justify-content-between py-4 my-4 border-top">
                            <p>© {(new Date().getFullYear())} Rapids Network v2. All rights reserved.</p>
                            <ul className="list-unstyled d-flex">
                                
                                <a className="me-1" href={'mailto:info@rapidsnetwork.io/'} title={'Rapids Network website'} target={'_blank'} rel="noreferrer"><img src={mail} width={25} height={25} alt={'mail'} /></a>
                                {' '}
                                <a className="me-1" href={'https://rapidsnetwork.medium.com/'} title={'Rapids Network Medium'} target={'_blank'} rel="noreferrer"><img src={medium} width={25} height={25} alt={'medium'} /></a>
                                {' '}
                                <a className="me-1" href={'https://www.reddit.com/r/RapidsNetwork/'} title={'Rapids Network Reddit'} target={'_blank'} rel="noreferrer"><img src={reddit} width={25} height={25} alt={'reddit'} /></a>
                                {' '}
                                <a className="me-1" href={'https://www.facebook.com/RapidsNetwork'} title={'Rapids Network Facebook'} target={'_blank'} rel="noreferrer"><img src={facebook} width={25} height={25} alt={'facebook'} /></a>
                                {' '}
                                <a className="me-1" href={'https://twitter.com/RapidsRPD'} title={'Rapids Network Twitter'} target={'_blank'} rel="noreferrer"><img src={twitter} width={25} height={25} alt={'twitter'} /></a>
                                {' '}
                                <a className="me-1" href={'https://bitcointalk.org/index.php?topic=5009962.0'} title={'Rapids Network BitcoinTalk Article'} target={'_blank'} rel="noreferrer"><img src={bitcoin} width={25} height={25} alt={'bitcoin'} /></a>
                                {' '}
                                <a className="me-1" href={'https://github.com/RapidsOfficial/'} title={'Rapids Network Github'} target={'_blank'} rel="noreferrer"><img src={github} width={25} height={25} alt={'github'} /></a>
                                {' '}
                                <a className="me-1" href={'https://t.me/RapticBot?start=crycy=SZrKiAUfd'} title={'Rapids Network Telegram'} target={'_blank'} rel="noreferrer"><img src={telegram} width={25} height={25} alt={'telegram'} /></a>
                                {' '}
                                <a href={'https://discord.com/oauth2/authorize?client_id=494125180386541569&scope=bot+applications.commands'} title={'Rapids Network Discord Server'} target={'_blank'} rel="noreferrer"><img src={discord} width={25} height={25} alt={'discord'} /></a>
                            </ul>
                        </Row>
                    </footer>
                </Container>

            </footer>
        
        </>
    );
}

export default Footer;
